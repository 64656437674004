import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AboutMethod from "../UI/AboutMethod";
import CardsSection from "../UI/cardsSection";
import SwiperCardsVideos from "../UI/swiperCardsVideos";
import Contact from "../UI/contact";
import ImageBackground from "../UI/imageBackground";
// import ActivitiesDynamic from "./activitiesDynamic";

import {
  SEO,
  CentersCoursesCardSectionData,
  CentersPlacesCardSectionData,
  Videos,
} from "../../dictionary/dictionary.json";
const { ActivitesCentersT } = SEO;

import "./activities.css";

const ActivitesCenters = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const content = () => {
    return (
      <div>
        <p>
          רכישת מיומנויות תכנות בגיל צעיר תורמת לפיתוח החשיבה האנליטית, לשיפור
          אוצר המילים באנגלית ולטיפוח היזמות, הדמיון והיצירתיות.
          <br />
          עם קורסי הדור הבא בשיטת דל"ת להצלחה (דמיון, למידה, תכנות) תעניקו
          לילדים זמן איכות ללמידה מעשירה ומהנה וכישורי חיים להצלחה!
        </p>
        <div style={{ marginBottom: "20px" }}>
          <button
            className="btn btn-primary"
            onClick={() => scrollToSection("centers")}
          >
            למרכזים
          </button>
          <button
            className="btn btn-primary"
            onClick={() => scrollToSection("courses")}
          >
            לרשימת הקורסים
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>{ActivitesCentersT.title}</title>
        <meta name="description" content={ActivitesCentersT.desc} />
        <meta name="title" content={ActivitesCentersT.title} />
        <meta name="keywords" content={ActivitesCentersT.tags} />
      </Helmet>

      <div className="activitiesContainer">
        <ImageBackground
          title={"קורסי הדור הבא"}
          subtitle={"במרכזי לימוד בארץ"}
          content={content}
          desktopSrc="/images/home/computer_class_in_afternoon_center_lg.webp"
          mobileSrc="/images/home/computer_class_in_afternoon_center.webp"
        />
        <div style={{ marginBottom: "20px" }}>
          <button
            className="btn btn-success"
            onClick={() => scrollToSection("centers")}
          >
            למרכזי הלימוד
          </button>
          <button
            className="btn btn-success"
            onClick={() => scrollToSection("courses")}
          >
            לרשימת הקורסים
          </button>
        </div>
        {/* <div style={{ width: "100%", height: "200px", color: "white" }}>
          <ActivitiesDynamic />
        </div> */}

        <AboutMethod />
        <div style={{ color: "black" }}>
          <div id="courses">
            <CardsSection
              title={CentersCoursesCardSectionData.title}
              cardsData={CentersCoursesCardSectionData.cardsData}
            />
            <div className="comments-container">
              <h2>תלמידים והורים ממליצים</h2>
              <SwiperCardsVideos data={Videos.data} />
            </div>
          </div>
          <div id="centers">
            <CardsSection
              title={CentersPlacesCardSectionData.title}
              cardsData={CentersPlacesCardSectionData.cardsData}
            />
          </div>
          <div>
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitesCenters;
