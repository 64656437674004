import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AboutMethod from '../UI/AboutMethod';
import CardsSection from '../UI/cardsSection';
import SwiperCardsVideos from '../UI/swiperCardsVideos';
import Contact from '../UI/contact';
import ImageBackground from '../UI/imageBackground';
import ScrollAnimation from '../UI/ScrollAnimation';

import {
  SEO,
  OnlineCoursesCardSectionData,
  Videos,
} from '../../dictionary/dictionary.json';
const { ActivitesOnlineT } = SEO;

import './activities.css';

const ActivitesOnline = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToSection = sectionId => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const ButtonCallToAction = () => {
    return (
      <div style={{ marginBottom: '5px', textAlign: 'center' }}>
        <button
          className="btn btnCallToActionOnline animate__animated animate__pulse animate__infinite"
          onClick={() => scrollToSection('register')}
        >
          להרשמה לשני שיעורי ניסיון בחינם
        </button>
      </div>
    );
  };

  const content = () => {
    return (
      <div>
        <p>
          רכישת מיומנויות תכנות בגיל צעיר תורמת לפיתוח החשיבה האנליטית, לשיפור
          אוצר המילים באנגלית ולטיפוח היזמות, הדמיון והיצירתיות.
          <br />
          עם קורסי הדור הבא בשיטת דל"ת להצלחה (דמיון, למידה, תכנות) תעניקו
          לילדים זמן איכות ללמידה מעשירה ומהנה וכישורי חיים להצלחה!
        </p>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>{ActivitesOnlineT.title}</title>
        <meta name="description" content={ActivitesOnlineT.desc} />
        <meta name="title" content={ActivitesOnlineT.title} />
        <meta name="keywords" content={ActivitesOnlineT.tags} />
      </Helmet>

      <div className="activitiesContainer">
        <ImageBackground
          title={'קורסי אונליין הדור הבא'}
          subtitle={'קורסי תכנות חווייתיים'}
          desktopSrc="/images/home/online_course_of_kids_learning_programming_lg.webp"
          mobileSrc="/images/home/online_course_of_kids_learning_programming.webp"
        />
        <div>
          {ButtonCallToAction()}

          <div>
            <h1>קורסי אונליין של הדור הבא</h1>
            <h3>הקורסים המקיפים והמתקדמים בארץ</h3>
          </div>
        </div>

        <div id="courses">
          <CardsSection cardsData={OnlineCoursesCardSectionData.cardsData} />
        </div>
        <div>
          <h1>מכינים את הילדים לעולם ההייטק</h1>
          {ButtonCallToAction()}
        </div>
        <ScrollAnimation animationType={'backInLeft'}>
          <div className="GoodToKnow">
            <h1>כדאי לדעת</h1>
            <p>
              ✔️מדריכים מנוסים בטכנולוגיות והדרכה, ומסורים לעזור ולנצל את מלוא
              הפוטנציאל של ילדכם
              <br />
              ✔️יחס אישי ועד 10 משתתפים בקבוצה בלבד
              <br />
              ✔️הקורסים שלנו מתאימים גם לילדים וילדות חסרי ניסיון בתכנות וגם
              לאלה שיש להם ניסיון והם רוצים להרחיב את הידע ואת כישורי התכנות
              שלהם
              <br />
              ✔️שירות ותמיכה בוואטסאפ או במייל 24/6 (מענה עד יום אחד לא כולל
              שבתות)
              <br />
              ✔️שיעורים מוקלטים הפתוחים לצפייה למשתתפים במהלך השנה
            </p>
          </div>
        </ScrollAnimation>
        <AboutMethod />
        <div style={{ color: 'black' }}>
          <div id="courses">
            {ButtonCallToAction()}
            <div className="comments-container">
              <h2>תלמידים והורים ממליצים</h2>
              <SwiperCardsVideos data={Videos.data} />
            </div>
          </div>

          <div id="register">
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitesOnline;
